window.onload = function () {
    var animationArray = {
        "icon-rocket-white": "/themes/notonpaper/assets/images/icons/ROCKET_W/data.json"
    };

    for (var key in animationArray) {
        if ($('#' + key).length) {
            var animData = bodymovin.loadAnimation({
                container: document.getElementById(key),
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: animationArray[key]
            });
        }
    }
};